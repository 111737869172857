import React from 'react'

import SEO from '../../components/seo'

import Layout from '../../components/layout'

import HeaderImg from '../../components/HeaderImg/HeaderImg'
import ProjectOverview from '../../components/ProjectOverview/ProjectOverview'
import TestimonialVideo from '../../components/TestimonialVideo/TestimonialVideo'
import TextBlock from '../../components/TextBlock/TextBlock'
import Slider from '../../components/Slider/Slider'
import Goals from '../../components/Goals/Goals'
import Outcomes from '../../components/Outcomes/Outcomes'
import BottomNav from '../../components/BottomNav/BottomNav'

import MarioLogo from '../../images/logo-mario-montana-solicitors.png'
import { GoogleAddsIcon } from '../../components/icons/GoogleAddsIcon'
import { WordPressIcon } from '../../components/icons/WordPressIcon'
import { SketchIcon } from '../../components/icons/SketchIcon'
import { HtmlIcon } from '../../components/icons/HtmlIcon'
import { CssIcon } from '../../components/icons/CssIcon'

const MarioPage = () => {
  const services = [
    {
      id: 1,
      text: 'Website Development',
    },
    {
      id: 2,
      text: 'Google Apps Setup',
    },
    {
      id: 3,
      text: 'Content Marketing',
    },
    {
      id: 4,
      text: 'Google Adwords',
    },
  ]

  const goals = [
    {
      id: 11,
      text: 'Establish a digital presence',
    },
    {
      id: 22,
      text: 'Automate business processes',
    },
    {
      id: 33,
      text: 'Gain credibility online',
    },
    {
      id: 44,
      text: 'Develop company branding',
    },
    {
      id: 55,
      text: 'Acquire new clients',
    },
  ]

  const outcomes = [
    {
      id: 111,
      title: 'Defined and Structured',
      subtitle: "client's services",
    },
    {
      id: 222,
      title: 'Digital Presence',
      subtitle: 'established',
    },
    {
      id: 333,
      title: 'Business Processes',
      subtitle: 'automated',
    },
    {
      id: 444,
      title: 'Brand Perception',
      subtitle: 'increased',
    },
  ]

  const techs = [
    {
      id: 1111,
      icon: GoogleAddsIcon(),
    },
    {
      id: 2222,
      icon: WordPressIcon(),
    },
    {
      id: 3333,
      icon: SketchIcon(),
    },
    {
      id: 4444,
      icon: HtmlIcon(),
    },
    {
      id: 5555,
      icon: CssIcon(),
    },
  ]

  const slides = [
    {
      id: 1,
      img:
        'https://cdn.emersoft.co/emersoft-website/mario-montana/home-page.jpg',
      alt: 'Mario Montana Solicitors Website',
    },
    {
      id: 2,
      img: 'https://cdn.emersoft.co/emersoft-website/mario-montana/slide-2.jpg',
      alt: 'Library',
    },
  ]

  return (
    <Layout>
      <SEO
        title="Mario Montana Solicitors"
        keywords={[
          `Emersoft`,
          `application`,
          `react`,
          `London`,
          `digital agency`,
        ]}
      />

      <HeaderImg
        header="header-img--mario"
        wrapper="header-img__img-wrapper--mario"
        img={MarioLogo}
      />
      <ProjectOverview
        title="Mario Montana Solicitors"
        link="https://mariomontana.com/"
        category='Law Firm'
        services={services}
        technologies={techs}
        subtitle="Mario Montana Solicitors is a niche legal practice based on Regent Street in the heart of London’s West End. From his office, Mario assists his esteemed clientele in a number of areas of the law."
        paragraph1="Such is the respect his customers have for him, they confidently recommend him to the clients of many leading professionals such as accountants, architects, financial advisers, and surveyors in and around London. He has assisted on a wide range of cases, tackling complex issues from a multitude of angles and his clients include individuals, SMEs, professionals, and enterprises of various sizes – traversing a range of sectors."
        paragraph2="He has assisted on a wide range of cases, tackling complex issues from a multitude of angles and his clients include individuals, SMEs, professionals, and enterprises of various sizes – traversing a range of sectors."
      />
      <TestimonialVideo
        url="https://youtu.be/7F2GCBlMi8g"
        author="Mario Montana"
        authorRole="Solicitor"
        thumbnail="https://cdn.emersoft.co/emersoft-website/mario-montana/slide-1.jpg"
        alt="Mario Montana"
      />
      <TextBlock
        heading="Brief"
        paragraph1="Mario Montana approached us to build a professional website for his small law firm that’s making a big impact, particularly in the family law area. Before we began working on the project, we soon discovered that there was more to do than just the website. There was a strong need to establish a digital presence on social media and Google My Business platform, as well as running a paid campaign to attract new clients."
        paragraph2="We were already familiar with the problems that Mario Montana Solicitors was facing. Most small and medium-size businesses have similar needs in relation to the digital world."
      />
      <Slider slides={slides} />
      <Goals goalsList={goals} />
      <TextBlock
        heading="Our Process"
        paragraph1="The process went very smoothly, as we have already established the most efficient way to work with this kind of business. We held an initial kick-off meeting to determine Mario's desired outcome to best support his legal practice through digital media. Our task was to listen and come up with a custom website designed in a manner that reflects his brand."
        paragraph2="Together with the client, we created a website sitemap. Then, we determined which social media channels were most appropriate. We came to the conclusion that LinkedIn, YouTube, X and Facebook would be the best choices we could possibly make due to LinkedIn's professional nature, YouTube's video branding power and Google position ranking impact, as well as Twitter's popularity among politicians and other well-regarded professions. We created eye-catching graphics, detailed copywriting of his services and positioned his brand on Google. Finally, we ran a well-targeted Google Adwords campaign that has resulted in calls to the client's practice."
      />
      <Outcomes outcomes={outcomes} fourItems />
      <BottomNav
        heading="See More"
        firstLink="/our-work/radium"
        firstHeading="Radium"
        secondLink="/our-work/burneikasports"
        secondHeading="Burneika Sports"
      />
    </Layout>
  )
}

export default MarioPage
