import React from 'react'

export const GoogleAddsIcon = () => {
  return (
    <svg width="40" height="39" xmlns="http://www.w3.org/2000/svg">
      <title>Google Ads</title>
      <g fillRule="nonzero" fill="none">
        <path
          d="M40 38.71H29.817a3.75 3.75 0 0 1-3.54-2.507L20.7 20.317l-.7-1.993.21-9.731L26.42.039l1.841 5.244L40 38.711z"
          fill="#8E8E8E"
        />
        <path
          fill="#7A7A7A"
          d="M28.261 5.283l-7.56 15.034L20 18.325l.21-9.732L26.42.04z"
        />
        <path
          d="M26.42.04L20 18.323l-6.279 17.878a3.75 3.75 0 0 1-3.539 2.509H0L13.579.039H26.42z"
          fill="#898989"
        />
      </g>
    </svg>
  )
}
